import React, { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";
import Tick from "../../Images/icons8-success-160.png";

import secureLocalStorage from "react-secure-storage";
import "./ThankYou.css";

import ImageLoader from "../admin/includes/ImageLoader";

export default function ThankYou() {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);

  var cert_details = secureLocalStorage.getItem("redeemed_info");
  var RedemObject = JSON.parse(cert_details);
  const isVoucher = secureLocalStorage.getItem("redeemDetail");

  useEffect(() => {
    if (isVoucher !== true || isVoucher === undefined || isVoucher === null) {
      navigate("/", { replace: true });
      setTimeout(() => {
        setShow(true);
      }, 500);
    } else {
      setShow(true);

      secureLocalStorage.setItem("redeemDetail", false);
    }
  }, []);

  return (
    <div className="contain">
      <div className="congrats">
        <div className="done">
          <img src={Tick} alt="tick-icon" />
        </div>
        <div className="sear-name">Dear {RedemObject?.fname}</div>
        <div className="order-done">Your Order Is Confirmed!</div>
        <div className="text">
          <p className="mt-3">
            You have successfully redeemed your voucher. <br />
            Here is your product details
            <br />
            <div className="form-check form-switch p-0 mt-2">
              <label className="form-check-label form-check-label-with-text mb-0  ">
                <span className="card-name-text mb-2 d-block fw-medium">
                  {RedemObject?.product}
                </span>

                <ImageLoader cardImg={RedemObject?.image} />
              </label>
            </div>
          </p>
        </div>
      </div>
    </div>
  );
}
