import React, { useEffect, useState } from "react";
import Logo from "../../Images/logo.webp";

import "./style.css";
import { useNavigate, useParams } from "react-router-dom";
import UserListService from "../../services/user-list.service";
import ReactLoading from "react-loading";
import secureLocalStorage from "react-secure-storage";
import { toast } from "react-toastify";

export default function VerifyVoucher() {
  const [show, setShow] = useState(true);
  const navigate = useNavigate();

  const { vId, pId } = useParams();

  const varify = async (voucher, product) => {
    try {
      const responce = await UserListService.voucherChecking(voucher, product);
      if (responce.status === 200) {
        const redeem = await UserListService.voucherRedeeming(voucher, product);
        if (responce.status === 200) {
          setShow(false);
          secureLocalStorage.setItem("redeemDetail", true);
          secureLocalStorage.setItem(
            "redeemed_info",
            JSON.stringify({
              product: redeem?.data?.response?.product,
              image: redeem?.data?.response?.image,
              fname: redeem?.data?.response?.fname,
              lname: redeem?.data?.response?.lname,
            })
          );
          navigate("/thank-you", { replace: true });
        }
      }
    } catch (err) {
      console.log(err);

      setShow(false);

      if (
        err?.response?.data?.response &&
        typeof err?.response?.data?.response === "string"
      ) {
        toast.error(err?.response?.data?.response, {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else if (
        err?.response?.data?.error &&
        typeof err?.response?.data?.error === "string"
      ) {
        toast.error("Voucher cannot be redeemed for the selected product.", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else {
        toast.error("Something went wrong, try again", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
      setTimeout(() => {
        window.location.href = "/";
      }, 1500);
    }
  };
  useEffect(() => {
    secureLocalStorage.removeItem("redeemDetail");
    secureLocalStorage.removeItem("redeemed_info");
    if (pId && vId) {
      varify(vId, pId);
    }
  }, []);

  return show ? (
    <div className="white-screen">
      <ReactLoading
        type="spinningBubbles"
        color="#185791"
        className="bar-loader"
      />
    </div>
  ) : (
    <></>
  );
}
