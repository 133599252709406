import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useLocation, useNavigate } from "react-router-dom";

export default function NotFound() {
  const locat = useLocation();

  const navigate = useNavigate();
  useEffect(() => {
    if (locat.pathname.indexOf("admin") > -1) {
      navigate("/admin/login");
    } else {
      navigate("/");
    }
  }, [locat.pathname]);

  return (
    <div className="white-screen"></div>
    // <div id="notfound">
    //   <Helmet>
    //     <title>404 Page Not Found</title>
    //   </Helmet>
    //   <div className="notfound">
    //     <div className="notfound-404">
    //       <h1>404</h1>
    //     </div>
    //     <h2>We are sorry, Page not found!</h2>
    //     <p>
    //       The page you are looking for might have been removed had its name
    //       changed or is temporarily unavailable.
    //     </p>
    //     <button
    //       onClick={() => {
    //         navigate(-1);
    //       }}
    //     >
    //       Back To Homepage
    //     </button>
    //   </div>
    // </div>
  );
}
