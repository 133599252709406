import React, { useLayoutEffect, useState } from "react";

import HeaderUser from "./Includes/HeaderUser";
import FooterUser from "./Includes/FooterUser";

import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export default function Help() {
  const [show, setShow] = useState(false);
  const navigate = useNavigate();

  const state = useSelector((state) => state.stateVals);
  const { certificate } = state;

  useLayoutEffect(() => {
    if (certificate) {
      setShow(true);
    } else {
      navigate("/", { replace: true });
      setTimeout(() => {
        setShow(true);
      }, 500);
    }
  }, []);

  return show ? (
    <div className="reward-body">
      <HeaderUser />
      <div className="main-content">
        <div className="container">
          <div className="row ">
            <div className="col-12">
              <div className="user-form home_div">
                <h3>Help</h3>
                <div className="m-ship">
                  <h2>Home</h2>
                  <p>
                    Welcome! The goal of this website is to bring you a wide
                    assortment of the products you love at varying point values,
                    and to provide you with a fun, fast and convenient award
                    selection experience! From the Home Page you can navigate
                    through all areas of the award selection site, as well as
                    view current Hot Picks and Specials.
                  </p>
                  <h2>Shopping Cart</h2>
                  <p>
                    Using the Shopping Cart is easy! Once you have found an item
                    that interests you, simply click the 'Add to Cart' button.
                    This button can be found on any item's detail page, below
                    the point information. You will be given the opportunity to
                    change your selections prior to checking out.
                  </p>
                  <ul class="help">
                    <li>
                      To change quantities of an item you have placed in your
                      Shopping Cart, change the number in the 'Qty' box.
                    </li>
                    <li>
                      To delete an item in your Shopping Cart, click the
                      'Remove' button that appears to the extreme left of any
                      item in your Shopping Cart.
                    </li>
                    <li>
                      Click the 'Empty Cart' button to delete all items from
                      your Cart and to start over.
                    </li>
                    <li>
                      Be sure to click the 'Update' button at the bottom of your
                      Shopping Cart to confirm any changes.
                    </li>
                    <li>
                      if items are no longer available from a manufacturer,
                      merchandise of equal or greater value may be substituted.
                    </li>
                    <li>
                      When you have finished adding items to your Shopping Cart
                      and are ready to check out, click the 'Checkout' button at
                      the bottom of the Shopping Cart page.
                    </li>
                  </ul>
                  <h2>Order History</h2>
                  <p>
                    The Order History page lets you view information about
                    orders you have placed in the past. You will see a list of
                    order numbers, order dates and total points spent. To view
                    the detail of a specific order, simply click on the order
                    number to see a description of the items ordered.
                  </p>
                  <h2>Contact Us</h2>
                  <p>
                    If you have any questions, or would like to send us your
                    comments, please click on 'Contact Us' to send us an email.
                    Make sure you include the name of the award program in which
                    you are participating and your username.{" "}
                    <a href="mailto:help@eliterewards.biz">
                      help@eliterewards.biz
                    </a>{" "}
                    or <a href="tel:866-354-8321">866-354-8321</a>
                  </p>
                  <h2>Logout</h2>
                  <p>
                    When you are finished using this site, please logout (Exit
                    Shop). We hope your award selection experience was positive!
                  </p>
                  <h2>Search</h2>
                  <p>
                    Search by brand, category or keyword/phrase. Simply enter a
                    description in the freeform typing area on the left side of
                    the screen, or click on 'Advanced Search' for more options.
                    Advanced Search allows you to search by Brand Name,
                    Description and/or point Value. Completing two or more of
                    these options will return more specific results.
                  </p>
                  <p>
                    On the left side of the screen, you can navigate through the
                    site by award category, such as Electronics or Housewares,
                    and sub-categories, such as Cameras or Cookware. All
                    Featured Brands with award items in these categories will be
                    shown through this navigation option.
                  </p>
                  <h2>Your Account</h2>
                  <p>
                    Each time you login, the right side of the screen will be
                    personalized just for you! Here you can view your current
                    points available to spend and the shopping cart total for
                    the current shopping session. The points available figure is
                    NOT updated during your shopping session. At checkout, if
                    you exceed your points available, the system will alert you.
                  </p>
                  <p>
                    Use the Wish List function as a personal motivator! Add
                    items to your Wish List (by clicking on the 'Add to Wish
                    List' button) that you want to order in the future when you
                    have accumulated the points required. Each time you log-on,
                    the Wish List will update to remind you of the products you
                    are interested in as you accumulate points. When you have
                    earned enough points to order an item on your Wish List, the
                    list will detail those items you are able to buy today and
                    those that you need to earn more points in order to
                    purchase. You are restricted to five items in your Wish
                    List. Click the 'Remove' button to delete an item from your
                    Wish List. Note: If a product becomes discontinued or is no
                    longer available, it will be deleted from your Wish List.
                  </p>
                  <h2>Specials</h2>
                  <p>
                    Occasionally, specific products are available at a
                    discounted point value. There may be a limited supply of
                    these products available. Upon each login different Specials
                    may appear on your Home Page. To view a complete list of all
                    current Specials, click on the 'All Specials' button.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FooterUser />
    </div>
  ) : (
    <div className="white-screen"></div>
  );
}
