import React, { useEffect } from "react";
import Sidebar from "../includes/Sidebar";
import Footer from "../includes/Footer";
import { useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";

import { useState } from "react";
import AdminListService from "../../../services/admin-list.service";

import MainHeading from "../includes/MainHeading";
import Skeleton from "react-loading-skeleton";
import Swal from "sweetalert2";
import Pagination from "react-js-pagination";
import { useFormik } from "formik";
import { quizSearch } from "../../../schema";
import moment from "moment";
import formatThousands from "format-thousands";

export default function JobDetail() {
  const { job } = useParams();

  const [tableLoader, settableLoader] = useState(false);
  const [jobLoader, setJobLoader] = useState(false);

  const [tableData, settableData] = useState([]);
  const [jobInfo, setJobInfo] = useState([]);
  const [limit, setLimit] = useState("10");

  const [currentPage, setCurrentPage] = useState(1);
  const [currentDateTime, setCurrentDateTime] = useState("");

  const [totalResults, setTotalResults] = useState("0");
  const [totalPages, setTotalPages] = useState("0");

  const state = useSelector((state) => state.stateVals);
  const { adminAccessToken, admin_id } = state;
  const navigate = useNavigate();

  const jobDetailByJob = async (page, limit, values) => {
    settableLoader(true);
    try {
      const responce = await AdminListService.getJobDetailByJob(
        page,
        limit,
        values,
        job
      );

      const res = responce?.data?.response?.response;

      const results = [];
      res.map((value) => {
        return results.push({
          city: value.city,
          fname: value.fname,
          lname: value.lname,
          issuance: value.issuance,
          expiration: value.expiration,
          status: value.status,
          job: value.job,
          CUSTOMER: value.company,
          certificate: value.certificate,
          state: value.state,
          vocher: value.vocher,
          zip: value.zip,
          street: value.address,
          points: value.denomination + " To " + value.denomination_end,
          voucher: value.voucher,
          email: value.email,
          phone: value.phone,
          use_date: value.use_date,
          id: value.id,
          choices: value.choices,
        });
      });

      settableData([...results]);
      setTotalPages(responce?.data?.response?.total_pages);
      setTotalResults(responce?.data?.response?.records);
      setCurrentPage(responce?.data?.response?.current_page);
      setLimit(responce?.data?.response?.per_page);

      settableLoader(false);
    } catch (err) {
      console.log(err);
      settableLoader(false);
      settableData([]);
      setTotalPages("0");
      setTotalResults("0");
    }
  };

  const jobInfoByJob = async () => {
    setJobLoader(true);
    try {
      const responce = await AdminListService.getJobDetailInfoByJob(job);

      const res = responce?.data?.response;

      const results = [];

      results.push({
        job: res.job,
        job_date: res.job_dates,
        job_percentage: res.used_percentage,
        total_cert: res.total_certificates,
        total_used_cert: res.total_used_certificates,
      });

      setJobInfo([...results]);
      setJobLoader(false);
    } catch (err) {
      console.log(err);
      setJobLoader(false);

      setJobInfo([]);
    }
  };

  useEffect(() => {
    if (adminAccessToken) {
      jobInfoByJob();
      jobDetailByJob("1", limit, []);

      const updateTimestamp = () => {
        const timestampInSeconds = Math.floor(Date.now() / 1000);
        setCurrentDateTime(timestampInSeconds);
      };

      const intervalId = setInterval(updateTimestamp, 1000);

      return () => clearInterval(intervalId);
    } else {
      navigate("/admin/login", { replace: true });
    }

    // eslint-disable-next-line
  }, []);

  const delVoucher = async (delId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "you want to delete record",
      icon: "question",
      iconColor: "#e04f5d",
      showCancelButton: true,
      confirmButtonColor: "#dc3545",
      confirmButtonText: "Yes",
      cancelButtonText: "Close",
      customClass: {
        container: "del-popup", // Apply your custom class to the container
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await AdminListService.deletSingleVouchers(
            delId,
            admin_id
          );

          jobInfoByJob();

          jobDetailByJob(currentPage, limit, values);

          Swal.fire({
            icon: "success",
            title: "Deleted Successfully!",
            showConfirmButton: false,
            timer: 1500,
          });
        } catch (err) {
          console.log(err);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            iconColor: "#e04f5d",
            confirmButtonColor: "#e72e2e",
            text: "Something went wrong!",
          });
        }
      }
    });
  };

  const { values, errors, touched, handleChange, handleSubmit } = useFormik({
    enableReinitialize: true,

    initialValues: {
      fieldtype: "",
      searchval: "",
    },
    validationSchema: quizSearch,
    onSubmit: (values) => {
      jobDetailByJob("1", limit, values);
    },
  });
  return (
    <div className="wrapper">
      <main className="page-content customer-contnent">
        <div className="site-main-design">
          <Sidebar />
          <MainHeading heading="Manage Your Redemption Codes" />

          <div className="mt-4 row">
            <div className="col-md-12 col-12">
              <div className="card dashboard-cards">
                <div className="bg-white  py-3 card-header">
                  <div className="row">
                    <div className="col-md-8">
                      <h4 className="mb-0">
                        Total Voucher:{" "}
                        <span className="text-primary-color fw-bold">
                          {formatThousands(totalResults, {
                            separator: ",",
                          })}
                        </span>
                      </h4>
                    </div>
                    <div className="col-md-4 text-end">
                      <a
                        className="btn btn-primary btn-sm btn-icon-text"
                        href={`${process.env.REACT_APP_API_Link}export-job-details/${job}?time=${currentDateTime}`}
                      >
                        Export As Excel Click here{" "}
                        <i className="bi bi-filetype-exe"></i>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <form className="row" onSubmit={handleSubmit} noValidate>
                    <div className="col-12 mb-5">
                      {jobLoader ? (
                        <Skeleton count={1} height="50px" />
                      ) : jobInfo.length ? (
                        jobInfo.map((el, index) => (
                          <div className="border-job-info" key={index}>
                            <div className="row">
                              <div className="col-lg-2">
                                <strong>Job Number</strong>
                                <span className="text-control">{el.job}</span>
                              </div>
                              <div className="col-lg-2">
                                <strong>Total Certificates </strong>
                                <span className="text-control">
                                  {el.total_cert}
                                </span>
                              </div>
                              <div className="col-lg-2">
                                <strong>Total Used Certificates </strong>
                                <span className="text-control">
                                  {el.total_used_cert}
                                </span>
                              </div>
                              <div className="col-lg-2">
                                <strong>
                                  Total Percentage Used Certificates
                                </strong>
                                <span className="text-control">
                                  {el.job_percentage}%
                                </span>
                              </div>
                              <div className="col-lg-2">
                                <strong>Begin Redemption </strong>
                                <span className="text-control">
                                  {el.job_date?.issuance &&
                                  el.job_date?.issuance !== "" &&
                                  el.job_date?.issuance !== "0000-00-00"
                                    ? moment(el.job_date?.issuance).format(
                                        "MM-DD-YYYY"
                                      )
                                    : "N/A"}
                                </span>
                              </div>
                              <div className="col-lg-2">
                                <strong>Expiration</strong>
                                <span className=" text-control text-danger">
                                  {el.job_date?.expiration &&
                                  el.job_date?.expiration !== "" &&
                                  el.job_date?.expiration !== "0000-00-00"
                                    ? moment(el.job_date?.expiration).format(
                                        "MM-DD-YYYY"
                                      )
                                    : "N/A"}
                                </span>
                              </div>
                            </div>
                          </div>
                        ))
                      ) : (
                        " No record found"
                      )}
                    </div>
                    <div className="col-lg-3">
                      <div className="input-field-box mb-lg-0">
                        <label>Show Column</label>
                        <select
                          className={`form-select ${
                            errors.fieldtype && touched.fieldtype
                              ? "is-danger"
                              : ""
                          }`}
                          onChange={handleChange}
                          name="fieldtype"
                          value={values.fieldtype || ""}
                          required
                        >
                          <option value="">Select Column</option>
                          <option value="job">Job</option>
                          <option value="company">Company Info</option>
                          <option value="voucher">Voucher</option>
                          <option value="fname">First Name</option>
                          <option value="lname">Last Name</option>
                          <option value="city">City</option>
                          <option value="state">State</option>
                          <option value="zip">Zip</option>
                          <option value="issuance">Begin Redemption</option>
                          <option value="expiration">Expiration</option>
                          <option value="choice">Choice</option>
                          <option value="status">Status</option>
                        </select>
                      </div>
                      {errors.fieldtype && touched.fieldtype ? (
                        <span className="text-danger">{errors.fieldtype}</span>
                      ) : null}
                    </div>

                    <div className="col-lg-6">
                      <div className="input-field-box mb-lg-0">
                        <label>Search</label>
                        <div className="input-group">
                          <input
                            type={
                              values.fieldtype === "issuance" ||
                              values.fieldtype === "expiration"
                                ? "date"
                                : "text"
                            }
                            className={`form-control ${
                              errors.searchval && touched.searchval
                                ? "is-danger"
                                : ""
                            }`}
                            onChange={handleChange}
                            name="searchval"
                            value={values.searchval || ""}
                            required
                          />

                          <button className="btn btn-primary " type="submit">
                            Search
                          </button>
                          <button
                            className="btn btn-primary"
                            type="reset"
                            onClick={() => window.location.reload(false)}
                          >
                            Reset
                          </button>
                        </div>
                      </div>
                      {errors.searchval && touched.searchval ? (
                        <span className="text-danger">{errors.searchval}</span>
                      ) : null}
                    </div>
                    <div className="col-md-3">
                      <div className="input-field-box mb-0">
                        <label>Show Entries</label>
                        <select
                          className="form-select"
                          value={limit}
                          onChange={(e) =>
                            jobDetailByJob("1", e.target.value, values)
                          }
                        >
                          <option value="10">10</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                          <option value="250">250</option>
                          <option value="500">500</option>
                        </select>
                      </div>
                    </div>
                  </form>
                </div>
                {tableLoader ? (
                  <div className="card-body pt-0">
                    <Skeleton count={11} height="50px" />
                  </div>
                ) : (
                  <div className="table-responsive">
                    <table className="text-nowrap  table">
                      <thead className="table-light">
                        <tr>
                          <th scope="col">No</th>
                          <th scope="col">Job Number</th>

                          <th scope="col">Company Info</th>
                          <th scope="col">Basic Info</th>
                          <th scope="col">Date</th>
                          <th scope="col">Assigned Products</th>
                          <th scope="col">Status</th>

                          <th scope="col">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {tableData.length ? (
                          tableData.map((el, index) => (
                            <tr key={index}>
                              <td className="align-middle">
                                {currentPage === 1
                                  ? index + 1
                                  : (currentPage - 1) * limit + index + 1}
                              </td>
                              <td className="align-middle">
                                <span className="text-control">{el.job}</span>
                              </td>
                              <td className="align-middle">
                                <table className="inner-table">
                                  <tbody>
                                    <tr>
                                      <td>
                                        <strong>Company Name: </strong>
                                      </td>
                                      <td>
                                        {" "}
                                        <span className="text-control">
                                          {el.CUSTOMER}
                                        </span>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <strong>Voucher Number: </strong>
                                      </td>
                                      <td>
                                        {" "}
                                        <span className="text-control">
                                          {el.voucher}
                                        </span>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <strong>Certificate: </strong>
                                      </td>
                                      <td>
                                        {" "}
                                        <span className="text-control">
                                          {el.certificate}
                                        </span>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <strong>Points: </strong>
                                      </td>
                                      <td>
                                        {" "}
                                        <span className="text-control">
                                          {el.points}
                                        </span>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                              <td className="align-middle">
                                <table className="inner-table">
                                  <tbody>
                                    <tr>
                                      <td>
                                        <strong>First Name:</strong>
                                      </td>
                                      <td>
                                        {" "}
                                        <span className="text-control">
                                          {el.fname}
                                        </span>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <strong>Last Name:</strong>
                                      </td>

                                      <td>
                                        {" "}
                                        <span className="text-control">
                                          {el.lname}
                                        </span>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <strong>Address:</strong>
                                      </td>
                                      <td>
                                        {" "}
                                        <span className="text-control">
                                          {el.street}
                                        </span>
                                      </td>
                                    </tr>

                                    <tr>
                                      <td>
                                        <strong>Zip:</strong>
                                      </td>
                                      <td>
                                        {" "}
                                        <span className="text-control">
                                          {el.zip}
                                        </span>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <strong>City:</strong>
                                      </td>
                                      <td>
                                        <span className="text-control">
                                          {el.city}
                                        </span>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <strong>State:</strong>
                                      </td>
                                      <td>
                                        <span className="text-control">
                                          {el.state}
                                        </span>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <strong>Phone:</strong>
                                      </td>
                                      <td>
                                        <span className="text-control">
                                          {el.phone}
                                        </span>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <strong>Email:</strong>
                                      </td>
                                      <td>
                                        <span className="text-control">
                                          {el.email}
                                        </span>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                              <td className="align-middle">
                                <table className="inner-table">
                                  <tbody>
                                    <tr>
                                      <td>
                                        <strong>Begin Date:</strong>
                                      </td>
                                      <td>
                                        <span className="text-control date-span">
                                          {el.issuance !== "" &&
                                            el.issuance !== "0000-00-00" &&
                                            moment(el.issuance).format(
                                              "MM-DD-YYYY"
                                            )}
                                        </span>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <strong>Expiration Date:</strong>
                                      </td>
                                      <td>
                                        <span className="text-control date-span">
                                          {el.expiration !== "" &&
                                            el.expiration !== "0000-00-00" &&
                                            moment(el.expiration).format(
                                              "MM-DD-YYYY"
                                            )}
                                        </span>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <strong>Redeemed Date:</strong>
                                      </td>
                                      <td>
                                        <span className="text-control date-span">
                                          {el.use_date &&
                                          el.use_date !== "" &&
                                          el.use_date !== "0000-00-00"
                                            ? moment(el.use_date).format(
                                                "MM-DD-YYYY"
                                              )
                                            : "N/A"}
                                        </span>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>

                              <td className="align-middle">
                                <span className="text-control">
                                  {el.choices.length ? (
                                    <ol>
                                      {el.choices.map((val) => (
                                        <li key={val.name}>{val.name}</li>
                                      ))}
                                    </ol>
                                  ) : (
                                    "N/A"
                                  )}
                                </span>
                              </td>
                              <td className="align-middle">
                                <span
                                  className={`text-control font-17 text-capitalize ${
                                    el.status === "active" ||
                                    el.status === "Valid"
                                      ? "text-success"
                                      : el.status === "used"
                                      ? "text-primary"
                                      : el.status === "expired" ||
                                        el.status === "archive"
                                      ? "text-danger"
                                      : ""
                                  }`}
                                >
                                  {el.status !== "" ? el.status : "N/A"}
                                </span>
                              </td>

                              <td className="align-middle ">
                                <div className="d-flex align-items-center">
                                  <Link
                                    to={`/admin/edit-voucher/${el.id}`}
                                    className="btn btn-success btn-sm btn-icon-text me-2"
                                  >
                                    Edit <i className="bi bi-pencil-square"></i>
                                  </Link>
                                  <button
                                    type="button"
                                    className="btn btn-danger btn-sm btn-icon-text"
                                    onClick={() => {
                                      delVoucher(el.id);
                                    }}
                                  >
                                    Delete <i className="bi bi-x-circle"></i>
                                  </button>
                                </div>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td
                              colSpan="8"
                              className="text-center text-capitalize"
                            >
                              No record found
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                    {totalResults > limit && totalPages > 1 ? (
                      <Pagination
                        activePage={currentPage}
                        itemsCountPerPage={parseInt(limit)}
                        totalItemsCount={totalResults}
                        onChange={(e) => {
                          jobDetailByJob(e, limit, values);
                        }}
                        pageRangeDisplayed={8}
                        itemClass="page-item"
                        linkClass="page-link"
                        firstPageText="First Page"
                        lastPageText="Last Page"
                      />
                    ) : (
                      ""
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
}
