import React, { useLayoutEffect, useState } from "react";
import { useParams } from "react-router-dom";
import HeaderUser from "./Includes/HeaderUser";
import FooterUser from "./Includes/FooterUser";
import UserListService from "../../services/user-list.service";
import Skeleton from "react-loading-skeleton";
import { useDispatch, useSelector } from "react-redux";
import Shipping from "./Includes/Shipping";
import ProductLoader from "./Includes/ProductLoader";
import { Rating } from "react-simple-star-rating";
import { toast } from "react-toastify";
import { actionCreaters } from "../../Redux";
import { bindActionCreators } from "redux";

export default function ProductDetail() {
  const [tableLoader, settableLoader] = useState(false);
  const [show, setShow] = useState(false);

  const [tableData, settableData] = useState([]);

  const { id } = useParams();
  const state = useSelector((state) => state.stateVals);
  const { certificate } = state;
  const dispatch = useDispatch();
  const userActions = bindActionCreators(actionCreaters, dispatch);
  const [disable, setDisable] = useState(false);

  const [ratingValue, setRatingValue] = useState(3.5);
  const [rated, setRated] = useState(false);
  const [tooltip, setTooltip] = useState(false);

  const handleRating = (rate) => {
    setRatingValue(parseInt(rate));
    setRated(true);
  };

  const productDetail = async () => {
    settableLoader(true);

    try {
      const responce = await UserListService.getProductInfo(id);
      console.log(responce);
      const res = responce?.data?.response;

      settableData(res);

      settableLoader(false);
    } catch (err) {
      console.log(err);
      settableData([]);
      settableLoader(false);
    }
  };

  useLayoutEffect(() => {
    if (certificate && id) {
      productDetail();
      setShow(true);
    } else {
      window.location.href = "/viewreward";
    }
  }, []);

  const productAddInCart = async (prdID) => {
    try {
      setDisable(true);
      const responce = await UserListService.addInCart(prdID, certificate);

      toast.success("Added to cart", {
        position: "top-center",
        autoClose: 1500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      userActions.cartCounter(certificate, "cartCounter");
      setDisable(false);
    } catch (err) {
      setDisable(false);

      if (
        err?.response?.data?.response &&
        typeof err?.response?.data?.response === "string"
      ) {
        toast.warn(err?.response?.data?.response, {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      } else {
        toast.error("Something went wrong, try again", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    }
  };

  return show ? (
    <div className="reward-body">
      <HeaderUser />
      <div className="main-content">
        <div className="container">
          <div className="user-form home_div">
            <Shipping />
            {tableLoader ? (
              <Skeleton width="100%" count={1} height="250px" />
            ) : (
              <>
                <h3 className="mb-2">
                  {tableData?.name ? tableData?.name : "N/A"}
                </h3>
                <div className="row">
                  <div className="col-md-5">
                    <div className="product-detail-img">
                      <ProductLoader cardImg={tableData?.image} />
                    </div>
                    {/* <div className="product-detail-img">
                      <p align="center"> Recently Viewed </p>
                      <div className="row">
                        <div className="col-4">
                          <img
                            className="img-fluid"
                            src={`${process.env.REACT_APP_IMAGE_Link}${tableData?.image}`}
                            alt="product"
                          />
                        </div>
                        <div className="col-4">
                          <img
                            className="img-fluid"
                            src={`${process.env.REACT_APP_IMAGE_Link}${tableData?.image}`}
                            alt="product"
                          />
                        </div>
                        <div className="col-4">
                          <img
                            className="img-fluid"
                            src={`${process.env.REACT_APP_IMAGE_Link}${tableData?.image}`}
                            alt="product"
                          />
                        </div>
                      </div>
                    </div> */}
                    {/* <div className="review-sub d-none d-md-block">
                      <div id="update">
                        <form>
                          <p className="mt-2 mb-1 fw-bold">
                            Submit your review
                          </p>
                          <textarea placeholder="Submit your review..."></textarea>

                          <input
                            type="button"
                            className="button"
                            value="Submit"
                            name="comment"
                          />
                        </form>
                      </div>
                    </div>
                    <div
                      class="accordion d-block d-md-none"
                      id="accordionExample"
                    >
                      <div class="accordion-item">
                        <h2 class="accordion-header">
                          <button
                            class="accordion-button collapsed review-accord"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseOne"
                            aria-expanded="false"
                            aria-controls="collapseOne"
                          >
                            Click here to submit your reviews
                          </button>
                        </h2>
                        <div
                          id="collapseOne"
                          class="accordion-collapse collapse "
                          data-bs-parent="#accordionExample"
                        >
                          <div class="accordion-body review-body">
                            <div className="review-sub mb-0">
                              <div id="update">
                                <form>
                                  <p className="mb-1 fw-bold">
                                    Submit your review
                                  </p>
                                  <textarea placeholder="Submit your review..."></textarea>

                                  <input
                                    type="button"
                                    className="button mb-0"
                                    value="Submit"
                                    name="comment"
                                  />
                                </form>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> */}
                  </div>
                  <div className="col-md-7">
                    <div className="desc-product">
                      <h2 className="d-none d-md-block">Description</h2>
                      <div className="desc-text d-none d-md-block">
                        <p>
                          {tableData?.description
                            ? tableData?.description
                            : "N/A"}
                        </p>
                      </div>
                      <div className="sect">
                        <div className="row">
                          <div className="col-md-6 col-sm-6 col-6">
                            <h4>Item Number:</h4>
                          </div>
                          <div className="col-md-6 col-sm-6 col-6">
                            <h4>
                              {tableData?.item_no ? tableData?.item_no : "N/A"}
                            </h4>
                          </div>
                        </div>
                      </div>
                      <div className="sect">
                        <div className="row">
                          <div className="col-md-6 col-sm-4 col-3">
                            <h4>Rating:</h4>
                          </div>
                          <div className="col-md-6 col-sm-8 col-9">
                            <Rating
                              onClick={handleRating}
                              showTooltip={tooltip}
                              onPointerEnter={() => {
                                setTooltip(true);
                              }}
                              onPointerLeave={() => {
                                setTooltip(false);
                              }}
                              transition={true}
                              allowFraction={true}
                              initialValue={3.5}
                              readonly={rated}
                              tooltipArray={[
                                "0.5 / 5",
                                "1 / 5",
                                "1.5 / 5",
                                "2 / 5",
                                "2.5 / 5",
                                "3 / 5",
                                "3.5 / 5",
                                "4 / 5",
                                "4.5 / 5",
                                "5 / 5",
                              ]}
                              style={{ marginTop: "-10px" }}
                            />
                          </div>
                        </div>
                      </div>
                      <h2 className="d-block d-md-none">Description</h2>
                      <div className="desc-text d-block d-md-none">
                        <p>
                          {tableData?.description
                            ? tableData?.description
                            : "N/A"}
                        </p>
                      </div>

                      <div className="sect">
                        <div className="row">
                          <div className="col-md-6"></div>
                          <div className="col-md-6">
                            <button
                              type="button"
                              onClick={() => {
                                productAddInCart(tableData?.id);
                              }}
                              className="button mb-0"
                              disabled={disable}
                            >
                              Add to Cart
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <div className="review-portion d-none d-md-block">
                      <p className="mt-2 fw-bold">Reviews about this product</p>
                      <div className="reviews ">
                        <ul>
                          <div
                            className="alert alert-primary d-flex align-items-center"
                            role="alert"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              class="bi bi-info-circle-fill me-3"
                              viewBox="0 0 16 16"
                            >
                              <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16m.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2"></path>
                            </svg>
                            <div>No review found for this product.</div>
                          </div>
                        </ul>
                      </div>
                    </div>

                    <div class="accordion d-block d-md-none" id="reviewView">
                      <div class="accordion-item">
                        <h2 class="accordion-header">
                          <button
                            class="accordion-button collapsed review-accord"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#view-review-1"
                            aria-expanded="false"
                            aria-controls="view-review-1"
                          >
                            Click to see reviews about product
                          </button>
                        </h2>
                        <div
                          id="view-review-1"
                          class="accordion-collapse collapse "
                          data-bs-parent="#reviewView"
                        >
                          <div class="accordion-body review-body">
                            <div className="review-portion p-0 m-0">
                              <p className=" fw-bold">
                                Reviews about this product
                              </p>
                              <div className="reviews ">
                                <ul>
                                  <div
                                    className="alert alert-primary d-flex align-items-center"
                                    role="alert"
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="16"
                                      height="16"
                                      fill="currentColor"
                                      class="bi bi-info-circle-fill me-3"
                                      viewBox="0 0 16 16"
                                    >
                                      <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16m.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2"></path>
                                    </svg>
                                    <div>No review found for this product.</div>
                                  </div>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <FooterUser />
    </div>
  ) : (
    <div className="white-screen"></div>
  );
}
