import React, { useEffect } from "react";
import Sidebar from "../includes/Sidebar";
import Footer from "../includes/Footer";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import { useState } from "react";
import AdminListService from "../../../services/admin-list.service";

import MainHeading from "../includes/MainHeading";
import Skeleton from "react-loading-skeleton";
import Swal from "sweetalert2";
import Pagination from "react-js-pagination";
import { useFormik } from "formik";
import { quizSearch } from "../../../schema";
import formatThousands from "format-thousands";

export default function ViewGifts() {
  const [tableLoader, settableLoader] = useState(false);

  const [tableData, settableData] = useState([]);
  const [limit, setLimit] = useState("10");
  const [currentDateTime, setCurrentDateTime] = useState("");

  const [currentPage, setCurrentPage] = useState(1);

  const [totalResults, setTotalResults] = useState("0");
  const [totalPages, setTotalPages] = useState("0");

  const state = useSelector((state) => state.stateVals);
  const { adminAccessToken, admin_id } = state;
  const navigate = useNavigate();

  const giftsLists = async (page, limit, values) => {
    settableLoader(true);
    try {
      const responce = await AdminListService.getGifts(page, limit, values);

      const res = responce?.data?.response?.response;

      const results = [];
      res.map((value) => {
        return results.push({
          name: value.name,
          image: value.image,
          type: value.type,
          description: value.description,

          points: value.points,

          id: value.id,
        });
      });

      settableData([...results]);
      setTotalPages(responce?.data?.response?.total_pages);
      setTotalResults(responce?.data?.response?.records);
      setCurrentPage(responce?.data?.response?.current_page);
      setLimit(responce?.data?.response?.per_page);

      settableLoader(false);
    } catch (err) {
      console.log(err);
      settableLoader(false);
      settableData([]);
      setTotalPages("0");
      setTotalResults("0");
    }
  };

  useEffect(() => {
    if (adminAccessToken) {
      giftsLists("1", limit, []);
      const updateTimestamp = () => {
        const timestampInSeconds = Math.floor(Date.now() / 1000);
        setCurrentDateTime(timestampInSeconds);
      };

      const intervalId = setInterval(updateTimestamp, 1000);

      return () => clearInterval(intervalId);
    } else {
      navigate("/admin/login", { replace: true });
    }

    // eslint-disable-next-line
  }, []);

  const delGift = async (delId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "you want to delete record",
      icon: "question",
      iconColor: "#e04f5d",
      showCancelButton: true,
      confirmButtonColor: "#dc3545",
      confirmButtonText: "Yes",
      cancelButtonText: "Close",
      customClass: {
        container: "del-popup", // Apply your custom class to the container
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await AdminListService.deletSingleGifts(
            delId,
            admin_id
          );

          giftsLists(currentPage, limit, values);

          Swal.fire({
            icon: "success",
            title: "Deleted Successfully!",
            showConfirmButton: false,
            timer: 1500,
          });
        } catch (err) {
          console.log(err);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            iconColor: "#e04f5d",
            confirmButtonColor: "#e72e2e",
            text: "Something went wrong!",
          });
        }
      }
    });
  };

  const deletAllGifts = async () => {
    Swal.fire({
      title: "Are you sure?",
      text: "you want to delete all record",
      icon: "question",
      iconColor: "#e04f5d",
      showCancelButton: true,
      confirmButtonColor: "#dc3545",
      confirmButtonText: "Yes",
      cancelButtonText: "Close",
      customClass: {
        container: "del-popup", // Apply your custom class to the container
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await AdminListService.deletAllGifts(admin_id);

          giftsLists(currentPage, limit, values);

          Swal.fire({
            icon: "success",
            title: "Deleted Successfully!",
            showConfirmButton: false,
            timer: 1500,
          });
        } catch (err) {
          console.log(err);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            iconColor: "#e04f5d",
            confirmButtonColor: "#e72e2e",
            text: "Something went wrong!",
          });
        }
      }
    });
  };

  const { values, errors, touched, handleChange, handleSubmit } = useFormik({
    enableReinitialize: true,

    initialValues: {
      fieldtype: "",
      searchval: "",
    },
    validationSchema: quizSearch,
    onSubmit: (values) => {
      giftsLists("1", limit, values);
    },
  });

  return (
    <div className="wrapper">
      <main className="page-content customer-contnent">
        <div className="site-main-design">
          <Sidebar />
          <MainHeading heading="Manage Products" />

          <div className="mt-4 row">
            <div className="col-md-12 col-12">
              <div className="card dashboard-cards">
                <div className="bg-white  py-3 card-header">
                  <div className="row">
                    <div className="col-md-5">
                      <h4 className="mb-0">
                        Total Products:{" "}
                        <span className="text-primary-color fw-bold">
                          {formatThousands(totalResults, {
                            separator: ",",
                          })}
                        </span>
                      </h4>
                    </div>
                    <div className="col-md-7 text-end">
                      <a
                        href={`${process.env.REACT_APP_API_Link}export-products-syg?time=${currentDateTime}`}
                        className="btn btn-primary btn-sm btn-icon-text me-2"
                      >
                        Export All Records{" "}
                        <i className="bi bi-filetype-exe"></i>
                      </a>
                      <button
                        onClick={deletAllGifts}
                        type="button"
                        className="btn btn-danger btn-sm btn-icon-text"
                      >
                        Delete All Records <i className="bi bi-x-circle"></i>
                      </button>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <form className="row" onSubmit={handleSubmit} noValidate>
                    <div className="col-lg-3">
                      <div className="input-field-box mb-lg-0">
                        <label>Show Column</label>
                        <select
                          className={`form-select ${
                            errors.fieldtype && touched.fieldtype
                              ? "is-danger"
                              : ""
                          }`}
                          onChange={handleChange}
                          name="fieldtype"
                          value={values.fieldtype || ""}
                          required
                        >
                          <option value="">Select Column</option>

                          <option value="name">Product Name</option>
                          <option value="points">Product Points</option>

                          <option value="description">Description </option>
                        </select>
                      </div>
                      {errors.fieldtype && touched.fieldtype ? (
                        <span className="text-danger">{errors.fieldtype}</span>
                      ) : null}
                    </div>

                    <div className="col-lg-6">
                      <div className="input-field-box mb-lg-0">
                        <label>Search</label>
                        <div className="input-group">
                          <input
                            type="text"
                            className={`form-control ${
                              errors.searchval && touched.searchval
                                ? "is-danger"
                                : ""
                            }`}
                            onChange={handleChange}
                            name="searchval"
                            value={values.searchval || ""}
                            required
                          />

                          <button className="btn btn-primary " type="submit">
                            Search
                          </button>
                          <button
                            className="btn btn-primary"
                            type="reset"
                            onClick={() => window.location.reload(false)}
                          >
                            Reset
                          </button>
                        </div>
                      </div>
                      {errors.searchval && touched.searchval ? (
                        <span className="text-danger">{errors.searchval}</span>
                      ) : null}
                    </div>
                    <div className="col-md-3">
                      <div className="input-field-box mb-0">
                        <label>Show Entries</label>
                        <select
                          className="form-select"
                          value={limit}
                          onChange={(e) =>
                            giftsLists("1", e.target.value, values)
                          }
                        >
                          <option value="10">10</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                          <option value="250">250</option>
                          <option value="500">500</option>
                        </select>
                      </div>
                    </div>
                  </form>
                </div>
                {tableLoader ? (
                  <div className="card-body pt-0">
                    <Skeleton count={11} height="50px" />
                  </div>
                ) : (
                  <div className="table-responsive">
                    <table className="text-nowrap  table">
                      <thead className="table-light">
                        <tr>
                          <th scope="col">No</th>
                          <th scope="col">Product Name</th>
                          <th scope="col">Points</th>

                          <th scope="col">Description</th>
                          <th scope="col">Image</th>
                          <th scope="col">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {tableData.length ? (
                          tableData.map((el, index) => (
                            <tr key={index}>
                              <td className="align-middle">
                                {currentPage === 1
                                  ? index + 1
                                  : (currentPage - 1) * limit + index + 1}
                              </td>

                              <td className="align-middle">
                                <span className="text-control text-capitalize">
                                  {el.name}
                                </span>
                              </td>
                              <td className="align-middle">
                                <span className="text-control text-capitalize">
                                  {el.points}
                                </span>
                              </td>

                              <td className="align-middle">
                                <span className="text-control">
                                  {el.description}
                                </span>
                              </td>
                              <td className="align-middle">
                                <img
                                  src={`${process.env.REACT_APP_IMAGE_Link}${el.image}`}
                                  alt=""
                                  className="gift-img"
                                />
                              </td>

                              <td className="align-middle ">
                                <div className="d-flex align-items-center">
                                  <Link
                                    to={`/admin/edit-product/${el.id}`}
                                    className="btn btn-success btn-sm btn-icon-text me-2"
                                  >
                                    Edit <i className="bi bi-pencil-square"></i>
                                  </Link>
                                  <button
                                    type="button"
                                    className="btn btn-danger btn-sm btn-icon-text"
                                    onClick={() => {
                                      delGift(el.id);
                                    }}
                                  >
                                    Delete <i className="bi bi-x-circle"></i>
                                  </button>
                                </div>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td
                              colSpan="6"
                              className="text-center text-capitalize"
                            >
                              No record found
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                    {totalResults > limit && totalPages > 1 ? (
                      <Pagination
                        activePage={currentPage}
                        itemsCountPerPage={parseInt(limit)}
                        totalItemsCount={totalResults}
                        onChange={(e) => {
                          giftsLists(e, limit, values);
                        }}
                        pageRangeDisplayed={8}
                        itemClass="page-item"
                        linkClass="page-link"
                        firstPageText="First Page"
                        lastPageText="Last Page"
                      />
                    ) : (
                      ""
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
}
