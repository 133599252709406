import React, { useLayoutEffect, useState } from "react";

import HeaderUser from "./Includes/HeaderUser";
import FooterUser from "./Includes/FooterUser";

import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export default function TOS() {
  const [show, setShow] = useState(false);
  const navigate = useNavigate();

  const state = useSelector((state) => state.stateVals);
  const { certificate } = state;

  useLayoutEffect(() => {
    if (certificate) {
      setShow(true);
    } else {
      navigate("/", { replace: true });
      setTimeout(() => {
        setShow(true);
      }, 500);
    }
  }, []);

  return show ? (
    <div className="reward-body">
      <HeaderUser />
      <div className="main-content">
        <div className="container">
          <div className="row ">
            <div className="col-12">
              <div className="user-form home_div">
                <h3>Terms and Conditions</h3>
                <div className="m-ship">
                  <p>
                    Elite Rewards would like to congratulate you on earning your
                    points. We want this to be an exciting and rewarding
                    experience for you.
                    <br />
                    <br />
                    Below are a few guidelines that will need to be followed to
                    help you when using the Reward Program. Please take a few
                    moments to read through and get familiarized with these
                    guidelines. In doing so, it helps ensure your happy
                    experience.
                    <br />
                    <br />
                    If you have any further questions, please do not hesitate to
                    reach out to our help desk at&nbsp;(866) 766-60358&nbsp;or
                    via email –&nbsp;
                    <a
                      href="mailto:help@eliterewards.biz"
                      style={{ color: "blue" }}
                    >
                      help@eliterewards.biz
                    </a>
                  </p>

                  <ul className="ps-3">
                    <li>
                      We reserve the right to substitute any product of equal or
                      greater value if product is unavailable.
                    </li>
                    <li>
                      There are no returns on any redemption that have been
                      shipped.
                    </li>
                    <li>
                      Any products damage during shipping should be refused and
                      contact Elite Rewards for immediate replacement.
                    </li>
                    <li>
                      Any defective products or products under warranty are not
                      handled by Elite Rewards, you need to contact the
                      manufacture for replacements.
                    </li>
                    <li>Installation of major appliances is not included.</li>
                    <li>
                      All shipments are PREPAID. Should the carrier, in error,
                      require you to pay for delivery of an item, make the
                      payment; then send your receipt to Award Headquarters,
                      Customer Service Department,&nbsp; Elite Rewards, 5111
                      Central Avenue. St, Petersburg, FL 33710&nbsp;for prompt
                      reimbursement.
                    </li>
                    <li>
                      We will not deliver to a PO Box, APO or outside the 48
                      continuous states.
                    </li>
                    <li>All sales are final.</li>
                    <li>
                      Please allow up to 4 weeks for delivery.&nbsp; Items may
                      be delivered at different times.
                    </li>
                  </ul>
                  <strong>TRAVEL</strong>
                  <br />
                  <ul className="ps-3">
                    <li>
                      Certificates for travel will be shipped following order.
                      Please allow 4-6 weeks
                    </li>
                    <li>
                      35-day advance notice required for all hotel destinations.
                    </li>
                    <li>
                      60-day advance notice required for all airfare, theme park
                      &amp; sports travel.
                    </li>
                    <li>
                      90-day advance notice required for all cruise travel.
                    </li>
                    <li>
                      Black Out Dates – Weeks of Thanksgiving, Christmas, New
                      Years, Memorial Day, and Independence Day. Other holidays
                      may be restricted based on availability given to Elite
                      Rewards.
                    </li>
                    <li>
                      Sales tax, delivery, service charges and all other
                      incidental charges not associated with actual material
                      sale are excluded from incentive dollar credit.
                    </li>
                    <li>
                      Participants may work with Elite Rewards to “upgrade”
                      trip, add days, rental cars, upgrade destination or
                      upgrade stateroom on a cruise.
                    </li>
                    <li>
                      All additional costs will be the responsibility of the
                      traveler. Upgrades are subject to availability.
                    </li>
                    <li>All travel requests are subject to availability.</li>
                    <li>
                      Certain other restrictions apply and are listed on the
                      travel voucher that you will receive upon qualifying and
                      requesting your package.
                    </li>
                    <li>
                      Trips may only be ordered and redeemed as outlined. These
                      promotional trips have no cash value and may not be
                      ordered and redeemed other than as outlined herein.
                    </li>
                    <li>
                      Participants using the trip assume any and all tax
                      liability (if applicable).
                    </li>
                    <li>
                      Void where prohibited by law or local ordinance.
                      <br />
                      <br />
                      Please allow 4-6 weeks to receive all products from the
                      time you receive the confirmation email that the product
                      was ordered. If you have multiple products they may come
                      at different times.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FooterUser />
    </div>
  ) : (
    <div className="white-screen"></div>
  );
}
